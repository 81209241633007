var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    [
      _c("div", { staticClass: "content_box mt10" }, [
        _c("div", { staticClass: "flex_box" }, [
          _c("span", { staticClass: "tot_num" }, [
            _vm._v(_vm._s(_vm.$t("msg.ONEX010T010.011"))),
            _vm._v(" : "),
            _c("span", { staticClass: "num" }, [
              _vm._v(_vm._s(_vm.totalCount)),
            ]),
          ]),
          _c("span", { staticClass: "input_box" }, [
            _c("input", {
              directives: [
                {
                  name: "model",
                  rawName: "v-model",
                  value: _vm.txtFilter,
                  expression: "txtFilter",
                },
              ],
              staticClass: "wid300",
              attrs: {
                type: "text",
                id: "filter",
                autocomplete: "off",
                placeholder: _vm.$t("msg.ONEX010T010.012"),
              },
              domProps: { value: _vm.txtFilter },
              on: {
                keyup: function ($event) {
                  return _vm.changeFilter()
                },
                input: function ($event) {
                  if ($event.target.composing) return
                  _vm.txtFilter = $event.target.value
                },
              },
            }),
          ]),
          _vm.relYn
            ? _c("span", { staticClass: "input_box" }, [
                _c(
                  "select",
                  {
                    directives: [
                      {
                        name: "model",
                        rawName: "v-model",
                        value: _vm.selPo,
                        expression: "selPo",
                      },
                    ],
                    staticClass: "wid100",
                    on: {
                      change: function ($event) {
                        var $$selectedVal = Array.prototype.filter
                          .call($event.target.options, function (o) {
                            return o.selected
                          })
                          .map(function (o) {
                            var val = "_value" in o ? o._value : o.value
                            return val
                          })
                        _vm.selPo = $event.target.multiple
                          ? $$selectedVal
                          : $$selectedVal[0]
                      },
                    },
                  },
                  [
                    _c("option", { attrs: { value: "POL" } }, [
                      _vm._v("Departure"),
                    ]),
                    _c("option", { attrs: { value: "POD" } }, [
                      _vm._v("Arrival"),
                    ]),
                  ]
                ),
              ])
            : _vm._e(),
          _vm.relYn
            ? _c("span", { staticClass: "input_box" }, [
                _c(
                  "select",
                  {
                    directives: [
                      {
                        name: "model",
                        rawName: "v-model",
                        value: _vm.selCtr,
                        expression: "selCtr",
                      },
                    ],
                    staticClass: "wid200",
                    on: {
                      change: [
                        function ($event) {
                          var $$selectedVal = Array.prototype.filter
                            .call($event.target.options, function (o) {
                              return o.selected
                            })
                            .map(function (o) {
                              var val = "_value" in o ? o._value : o.value
                              return val
                            })
                          _vm.selCtr = $event.target.multiple
                            ? $$selectedVal
                            : $$selectedVal[0]
                        },
                        function ($event) {
                          return _vm.checkSelectCtr()
                        },
                      ],
                    },
                  },
                  [
                    _c("option", { attrs: { value: "" } }, [
                      _vm._v(_vm._s(_vm.$t("msg.ONEX010T010.004"))),
                    ]),
                    _vm._l(_vm.ctrCdList, function (ctrInfo) {
                      return _c(
                        "option",
                        {
                          key: ctrInfo.ctrCd,
                          domProps: { value: ctrInfo.ctrCd },
                        },
                        [_vm._v(_vm._s(ctrInfo.ctrEnm))]
                      )
                    }),
                  ],
                  2
                ),
              ])
            : _vm._e(),
          _c("span", { staticClass: "ml_auto cal_btn" }, [
            _c(
              "a",
              {
                staticClass: "button sm",
                attrs: { href: "#" },
                on: {
                  click: function ($event) {
                    $event.preventDefault()
                    return _vm.exlDownload()
                  },
                },
              },
              [_vm._v(_vm._s(_vm.$t("msg.MYIN020T010.016")))]
            ),
          ]),
        ]),
        _c("div", {
          style: `width: 100%; height: ${_vm.gridHeight}px`,
          attrs: { id: "realgrid" },
        }),
      ]),
      _c("div", { staticClass: "flex_box mt10" }, [
        _c(
          "a",
          {
            staticClass: "button blue lg ml_auto mr5",
            attrs: { href: "#" },
            on: {
              click: function ($event) {
                $event.preventDefault()
                return _vm.fnAction("TAX")
              },
            },
          },
          [_vm._v(_vm._s(_vm.$t("msg.ONGO080G010.034")))]
        ),
      ]),
      _c("common-unsolved", {
        ref: "commonUnsolved",
        on: { callback: _vm.searchAct, popupOpen: _vm.setCurrentParams },
      }),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }